import { call, put, takeLatest } from 'redux-saga/effects';
import { REGIS } from '../../constants/auth/regis';

import {
  registerSuccess,
  registerFailed,
  registerByGoogleSuccess,
  registerByGoogleFailed,
} from '../../actions/auth/regis';

import qs from 'qs';
import { regisGoogle, register } from '../../../services/AuthService';

function* registerSaga(data) {
  let payload = {
    email: data.data.email,
    type: 'Consumer',
    password: data.data.password,
    date_of_birth: data.data.date_of_birth,
    full_name: data.data.full_name,
    phone_number: data.data.phone_number,
    phone_country_code: data.data.phone_country_code,
    // role_id: null,
    elearning_platform: window.location.hostname,
    gender: data.data.gender,
    bio: '',
  };

  if (data.data.provider !== '') {
    payload = {
      ...payload,
      provider: data.data.provider,
      providerId: data.data.providerId,
    };
  }

  const registerReq = yield call(register, payload);
  if (registerReq.status === 200) {
    yield put(registerSuccess(registerReq));
  } else {
    yield put(registerFailed(registerReq));
  }
}

function* watchSaga() {
  yield takeLatest(REGIS.REGISTER, registerSaga);
}

export default watchSaga;
