import { createSlice } from '@reduxjs/toolkit';

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: {
    vouchers: [],
    loadingVouchers: false,
    errorVouchers: null,
    voucherExchanged: {},
    loadingVoucherExchanged: false,
    errorVoucherExchanged: null,
    page: 1,
    limit: 10,
    search: '',
  },
  reducers: {
    fetchVouchersStart(state, action) {
      state.loadingVouchers = true;
      state.errorVouchers = null;

      const {
        page,
        limit,
        q: { name_cont: search },
      } = action.payload;
      const { page: oldPage, limit: oldLimit, search: oldSearch } = state;

      oldPage !== page && (state.page = page);
      oldLimit !== limit && (state.limit = limit);
      oldSearch !== search && (state.search = search);
    },
    fetchVouchersSuccess(state, action) {
      state.loadingVouchers = false;
      state.vouchers = action.payload;
    },
    fetchVouchersFailure(state, action) {
      state.loadingVouchers = false;
      state.errorVouchers = action.payload;
    },
    redeemVoucherRequest: (state) => {
      state.loadingVoucherExchanged = true;
      state.voucherExchanged = null;
      state.errorVoucherExchanged = null;
    },
    redeemVoucherSuccess: (state, action) => {
      state.loadingVoucherExchanged = false;
      state.voucherExchanged = action.payload;
      state.errorVoucherExchanged = null;
    },
    redeemVoucherFailure: (state, action) => {
      state.loadingVoucherExchanged = false;
      state.voucherExchanged = {};
      state.errorVoucherExchanged = action.payload;
    },
  },
});

export const {
  fetchVouchersStart,
  fetchVouchersSuccess,
  fetchVouchersFailure,
  redeemVoucherRequest,
  redeemVoucherFailure,
  redeemVoucherSuccess,
} = voucherSlice.actions;

export default voucherSlice.reducer;
