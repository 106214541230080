import { MENTOR } from '../../constants/learning/mentor';

export const getMentorById = (id) => ({
  type: MENTOR.GET_MENTOR_BY_ID,
  id,
});
export const getMentorByIdSuccess = (payload) => ({
  type: MENTOR.GET_MENTOR_BY_ID_SUCCESS,
  payload,
});
export const getMentorByIdFailed = (payload) => ({
  type: MENTOR.GET_MENTOR_BY_ID_FAILED,
  payload,
});

export const getAllMentor = () => ({
  type: MENTOR.GET_ALL_MENTOR,
});
export const getAllMentorSuccess = (payload) => ({
  type: MENTOR.GET_ALL_MENTOR_SUCCESS,
  payload,
});
export const getAllMentorFailed = (payload) => ({
  type: MENTOR.GET_ALL_MENTOR_FAILED,
  payload,
});
