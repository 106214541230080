export const MENTOR = {
  //get all mentor
  GET_ALL_MENTOR: 'LEARNING_GET_ALL_MENTOR ',
  GET_ALL_MENTOR_SUCCESS: 'LEARNING_GET_ALL_MENTOR_SUCCESS',
  GET_ALL_MENTOR_FAILED: 'LEARNING_GET_ALL_MENTOR_FAILED',

  //get mentor by id
  GET_MENTOR_BY_ID: 'LEARNING_GET_MENTOR_BY_ID',
  GET_MENTOR_BY_ID_SUCCESS: 'LEARNING_GET_MENTOR_BY_ID_SUCCESS',
  GET_MENTOR_BY_ID_FAILED: 'LEARNING_GET_MENTOR_BY_ID_FAILED',
};
