import React from 'react';
import './index.css';

const Loading = ({ text = true, size = 'medium' }) => {
  return (
    <div className='container-loading'>
      <div
        className={`spinner-border ${
          size === 'medium'
            ? 'spinner-medium'
            : size === 'small'
              ? 'spinner-small'
              : 'spinner-medium'
        }`}
        role='status'
      >
        <span className='sr-only'>Loading...</span>
      </div>
      {text ? <p>Loading.....</p> : null}
    </div>
  );
};

export default Loading;
