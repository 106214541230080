export const LOGIN = {
  LOGIN: 'AUTH_LOGIN',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILED: 'AUTH_LOGIN_FAILED',
  LOGOUT: 'AUTH_LOGOUT',
  LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  LOGOUT_FAILED: 'AUTH_LOGOUT_FAILED',
  // google
  LOGIN_WITH_GOOGLE: 'AUTH_LOGIN_WITH_GOOGLE',
  LOGIN_WITH_GOOGLE_SUCCESS: 'AUTH_LOGIN_WITH_GOOGLE_SUCCESS',
  LOGIN_WITH_GOOGLE_FAILED: 'AUTH_LOGIN_WITH_GOOGLE_FAILED',

  // forgot password
  FORGOT_PASSWORD: 'AUTH_FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'AUTH_FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'AUTH_FORGOT_PASSWORD_FAILED',

  // reset status
  RESET_STATUS: 'AUTH_RESET_STATUS',
};
