import { authCMSRequest } from '../Api';
import qs from 'qs';

export const getVoucher = (data) =>
  authCMSRequest.get('/api/v1/vouchers/history?' + qs.stringify(data), {});

export const requestRedeemVoucher = (data) =>
  authCMSRequest.post('/api/v1/bookings/apply_voucher', qs.stringify(data), {
    validateStatus: (status) => status >= 200 && status < 300,
  });
