import { call, put, takeLatest, delay, takeEvery } from 'redux-saga/effects';
import { MENTOR } from '../../constants/learning/mentor';
import { getMentorById, getAllMentor } from '../../../services/MentorService';
import {
  getAllMentorFailed,
  getAllMentorSuccess,
  getMentorByIdFailed,
  getMentorByIdSuccess,
} from '../../actions/learning/mentor';

function* mentorByIdSaga({ id }) {
  const mentorByIdReq = yield call(getMentorById, id);
  if (mentorByIdReq.status === 200) {
    yield put(getMentorByIdSuccess(mentorByIdReq));
  } else {
    yield put(getMentorByIdFailed(mentorByIdReq));
  }
}

function* mentorSaga() {
  const mentorReq = yield call(getAllMentor);
  if (mentorReq.status === 200) {
    yield put(getAllMentorSuccess(mentorReq));
  } else {
    yield put(getAllMentorFailed(mentorReq));
  }
}

function* watchSaga() {
  yield takeLatest(MENTOR.GET_ALL_MENTOR, mentorSaga);
  yield takeLatest(MENTOR.GET_MENTOR_BY_ID, mentorByIdSaga);
}

export default watchSaga;
