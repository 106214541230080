import React from 'react';

import PrivateRoute from '../utils/PrivateRoute';

//error
const NotFoundPage = React.lazy(() => import('../component/NotFound'));

//test component
const TestComponent = React.lazy(
  () => import('../component/pages/TestComponent'),
);

//home
const Home = React.lazy(() => import('../pages/Home'));
const TermsCondition = React.lazy(() => import('../component/pages/Terms'));
const Privacy = React.lazy(() => import('../component/pages/Privacy'));
const About = React.lazy(() => import('../component/pages/About'));
const ContactUs = React.lazy(() => import('../component/ContactUs'));
const Faq = React.lazy(() => import('../component/Faq'));
const Help = React.lazy(() => import('../component/Help'));
const Bootcamp = React.lazy(() => import('../component/bootcamp/index'));
const Banner = React.lazy(() => import('../component/BannerContent'));
const Programmes = React.lazy(() => import('../component/ProgramContent'));
const RefundPolicy = React.lazy(() => import('../component/pages/Refund'));
const Cancellation = React.lazy(
  () => import('../component/pages/Cancellation'),
);

//auth
const Login = React.lazy(() => import('../component/authentication/'));
const Register = React.lazy(
  () => import('../component/authentication/register'),
);
const ForgotPassword = React.lazy(
  () => import('../component/authentication/Login/ForgotPassword'),
);
const ChangePassword = React.lazy(
  () => import('../component/profile/ChangePassword'),
);

//course
const Education = React.lazy(() => import('../component/education'));
const EducationDetail = React.lazy(
  () => import('../component/education/EducationDetail'),
);
const DetailCourse = React.lazy(
  () => import('../component/profile/DetailCourse'),
);
const CourseByCategory = React.lazy(
  () => import('../component/education/CourseByCategory'),
);
const SearchedCourse = React.lazy(
  () => import('../component/education/SearchedCourse'),
);
const Score = React.lazy(() => import('../component/quiz/Score'));
const Quiz = React.lazy(() => import('../component/quiz'));

//learning
const Learning = React.lazy(() => import('../component/learning'));

//profile
const Profile = React.lazy(() => import('../component/profile'));
const EditProfile = React.lazy(
  () => import('../component/profile/EditProfile'),
);
const MyTransaction = React.lazy(
  () => import('../component/profile/MyTransaction'),
);
const Payment = React.lazy(() => import('../component/Payment'));
const MyVoucher = React.lazy(() => import('../component/profile/MyVoucher'));

//quiz
const ReviewAnswer = React.lazy(() => import('../component/quiz/ReviewAnswer'));

//training
const Career = React.lazy(() => import('../component/Career'));

const Articles = React.lazy(() => import('../component/Articles'));
const CorporateSolution = React.lazy(
  () => import('../component/CorporateSolution'),
);

//always put the object below if you add new page/component
export const routes = [
  {
    name: 'Test Component',
    path: '/test',
    element: <TestComponent />,
  },
  {
    name: 'Not Found Page',
    path: '*',
    element: <NotFoundPage />,
  },
  {
    name: 'Banner Content',
    path: '/banner/:id',
    element: <Banner />,
  },
  {
    name: 'Programmes Content',
    path: '/program/:id',
    element: <Programmes />,
  },

  {
    name: 'Bootcamp',
    path: '/bootcamps',
    element: <Bootcamp />,
  },
  {
    name: 'Refund Policy',
    path: '/refund',
    element: <RefundPolicy />,
  },
  {
    name: 'Cancellation',
    path: '/cancellation',
    element: <Cancellation />,
  },
  {
    name: 'Articles',
    path: '/articles',
    element: <Articles />,
  },
  {
    name: 'Corporate',
    path: '/corporate-solution',
    element: <CorporateSolution />,
  },
  {
    name: 'Career',
    path: '/career',
    element: <Career />,
  },
  {
    name: 'Home',
    path: '/',
    element: <Home />,
  },
  {
    name: 'Terms & Conditions',
    path: '/terms',
    element: <TermsCondition />,
  },
  {
    name: 'Privacy Police',
    path: '/privacy',
    element: <Privacy />,
  },
  {
    name: 'About',
    path: '/about',
    element: <About />,
  },
  {
    name: 'Contact Us',
    path: '/contact',
    element: <ContactUs />,
  },
  {
    name: 'Faq',
    path: '/faq',
    element: <Faq />,
  },
  {
    name: 'Faq Detail',
    path: '/help',
    element: <Help />,
  },
  {
    name: 'Login',
    path: '/login',
    element: <Login />,
  },
  {
    name: 'Register',
    path: '/register',
    element: <Register />,
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    name: 'Change Password',
    path: '/profile/changepassword',
    element: (
      <PrivateRoute>
        <ChangePassword />
      </PrivateRoute>
    ),
  },
  {
    name: 'Education',
    path: '/education',
    element: <Education />,
  },
  {
    name: 'Education Detail',
    path: '/education/:slug',
    element: (
      <PrivateRoute>
        <EducationDetail />
      </PrivateRoute>
    ),
  },
  {
    name: 'Education Detail',
    path: '/profile/detailcourse/:id',
    element: (
      <PrivateRoute>
        <DetailCourse />
      </PrivateRoute>
    ),
  },

  {
    name: 'Course By Categori',
    path: '/education/category/:category',
    element: <CourseByCategory />,
  },
  {
    name: 'Searched Course',
    path: '/course-list',
    element: <SearchedCourse />,
  },
  {
    name: 'Score ',
    path: '/quiz/finished',
    element: (
      <PrivateRoute>
        <Score />
      </PrivateRoute>
    ),
  },
  {
    name: 'Review Answer',
    path: '/quiz/finished/review-answer',
    element: (
      <PrivateRoute>
        <ReviewAnswer />
      </PrivateRoute>
    ),
  },
  {
    name: 'Quiz ',
    path: '/quiz/:quiz_id',
    element: (
      <PrivateRoute>
        <Quiz />
      </PrivateRoute>
    ),
  },
  {
    name: 'Learning',
    path: '/learning/:id',
    element: (
      <PrivateRoute>
        <Learning />
      </PrivateRoute>
    ),
  },
  {
    name: 'Profile',
    path: '/profile/mycourse',
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    name: 'Edit Profile',
    path: '/profile/edit',
    element: (
      <PrivateRoute>
        <EditProfile />
      </PrivateRoute>
    ),
  },
  {
    name: 'My Voucher',
    path: '/profile/voucher',
    element: (
      <PrivateRoute>
        <MyVoucher />
      </PrivateRoute>
    ),
  },
  {
    name: 'My Transaction',
    path: '/profile/transaction',
    element: (
      <PrivateRoute>
        <MyTransaction />
      </PrivateRoute>
    ),
  },
  {
    name: 'Payment',
    path: '/payment',
    element: (
      <PrivateRoute>
        <Payment />
      </PrivateRoute>
    ),
  },
];
