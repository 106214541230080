import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchVouchersFailure,
  fetchVouchersStart,
  fetchVouchersSuccess,
  redeemVoucherFailure,
  redeemVoucherRequest,
  redeemVoucherSuccess,
} from '../../Voucher';
import {
  getVoucher,
  requestRedeemVoucher,
} from '../../../services/VoucherService';

function* redeemVoucher({ payload: { id, voucher } }) {
  try {
    console.log('redeemUserSaga:', id, voucher);
    const vouchers = yield call(requestRedeemVoucher, id, voucher);
    console.log('requestRedeemVoucher response:', vouchers);
    yield put(redeemVoucherSuccess(vouchers.data));
  } catch (error) {
    console.log('redeemUserSaga error:', error);
    yield put(redeemVoucherFailure(error));
  }
}

function* fetchUsageVoucher({ payload }) {
  try {
    const vouchers = yield call(getVoucher, payload);
    yield put(fetchVouchersSuccess(vouchers));
  } catch (error) {
    yield put(fetchVouchersFailure(error));
  }
}

function* watchSaga() {
  yield takeLatest(redeemVoucherRequest.type, redeemVoucher);
  yield takeLatest(fetchVouchersStart.type, fetchUsageVoucher);
}

export default watchSaga;
