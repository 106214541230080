import axios from 'axios';
import { authEDURequest } from '../../store/Api';

export const fetchRepos = async () => {
  const response = await axios.get(
    'https://api.github.com/repos/tannerlinsley/react-query',
  );
  return response.data;
};

export const fetchBanner = async () => {
  const response = await authEDURequest.get('/api/banner/get-banner');
  return response.data.data;
};

export const fetchDataCompany = async () => {
  const response = await authEDURequest.get('/api/general/get-general');
  return response?.data?.data;
};

export const fetchFeatures = async () => {
  const response = await authEDURequest.get('/api/features/get-features');
  return response.data.data;
};

export const fetchTestimoni = async () => {
  const response = await authEDURequest.get('/api/testimonies/get-testimonies');
  return response.data.data;
};

export const fetchProgrammes = async () => {
  const response = await authEDURequest.get('/api/programmes/get-programmes');
  return response?.data?.data;
};

export const fetchAbout = async () => {
  const response = await authEDURequest.get('/api/about/get-about');
  return response.data.data;
};

export const fetchHelp = async () => {
  const response = await authEDURequest.get('/api/help-page/get-help-page');
  return response.data.data;
};

export const fetchFaq = async (queryParams) => {
  const response = await authEDURequest.get('/api/faq/get-faq', {
    params: queryParams,
  });
  return response.data.data;
};

export const fetchTerms = async () => {
  const response = await authEDURequest.get(
    '/api/terms-condition/get-terms-condition',
  );
  return response.data.data;
};

export const fetchPrivacy = async () => {
  const response = await authEDURequest.get('/api/privacy/get-privacy');
  return response.data.data;
};

export const fetchRefund = async () => {
  const response = await authEDURequest.get('/api/refund-policy/get-refund');
  return response.data.data;
};

export const fetchCancellation = async () => {
  const response = await authEDURequest.get(
    '/api/cancellation/get-cancellation',
  );
  return response.data.data;
};
