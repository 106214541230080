import React from 'react';
import logoLogin from '../assets/img/logo-geekfarms.svg';

const Loading = () => {
  return (
    <div
      style={{
        height: '100vh',
        background: '#fff',
      }}
      className='d-flex justify-content-center align-items-center'
    >
      <div className='d-flex flex-column align-items-center'>
        <img
          src={logoLogin}
          alt='GeeksFarm'
          width={200}
          className='animation-zoom-in-out '
        />
        <p style={{ width: '50%', textAlign: 'center', fontWeight: '700' }}>
          Loading...
        </p>
      </div>
    </div>
  );
};

export default Loading;
