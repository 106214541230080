import React from 'react';

import Header from './header';

import ImageErrorPage from '../assets/img/error-page.svg';

const ErrorPage = () => {
  const handleContactSupport = () => {
    window.location.href = 'mailto:info@wegeeks.co';
  };
  return (
    <>
      <div
        style={{
          height: '100vh',
          background: 'linear-gradient(180deg, #802EC0 53.17%, #994AD7 69.62%)',
        }}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='d-flex flex-column align-items-center'>
          <img src={ImageErrorPage} alt='404 - Page Not Found' width={500} />
          <h1 className='fw-bold mt-5' style={{ color: '#fff' }}>
            Internal server error
          </h1>
          <p style={{ width: '50%', textAlign: 'center', color: '#fff' }}>
            The server encountered an internal error or misconfiguration and was
            unable to complete your request.
          </p>
          <button
            type='button'
            className='btn btn-primary-custom'
            onClick={handleContactSupport}
          >
            {' '}
            CONTACT SUPPORT
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
