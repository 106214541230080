import { MENTOR } from '../../constants/learning/mentor';

const initialState = {
  status: '',
  message: '',
  mentorById: {},
  mentors: {},
};

const mentor = (state = initialState, action) => {
  switch (action.type) {
    //get mentor by id
    case MENTOR.GET_MENTOR_BY_ID:
      return {
        ...state,
        status: 'request',
      };
    case MENTOR.GET_MENTOR_BY_ID_SUCCESS:
      return {
        ...state,
        status: 'success',
        mentorById: action.payload,
      };
    case MENTOR.GET_MENTOR_BY_ID_FAILED:
      return {
        ...state,
        status: 'failed',
        message: action.payload,
      };

    //get all mentor
    case MENTOR.GET_ALL_MENTOR:
      return {
        ...state,
        status: 'request',
      };
    case MENTOR.GET_ALL_MENTOR_SUCCESS:
      return {
        ...state,
        status: 'success',
        mentors: action.payload,
      };

    case MENTOR.GET_ALL_MENTOR_FAILED:
      return {
        ...state,
        status: 'failed',
        message: action.payload,
      };
    default:
      return state;
  }
};

export default mentor;
