import React from 'react';
import { useNavigate } from 'react-router';

import Header from './header';

import ImageNotFound from '../assets/img/not-found-page.svg';

const NotFoundOrigin = () => {
  const navigate = useNavigate();
  const handleBackHome = () => {
    navigate('/');
  };

  return (
    <>
      <div
        style={{
          height: '100vh',
          background: 'linear-gradient(180deg, #802EC0 53.17%, #994AD7 69.62%)',
        }}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='d-flex flex-column align-items-center'>
          <img src={ImageNotFound} alt='404 - Page Not Found' width={500} />
          <h1 className='fw-bold mt-5' style={{ color: '#fff' }}>
            Page not found
          </h1>
          <p style={{ width: '50%', textAlign: 'center', color: '#fff' }}>
            Looks like you've followed a broken link or entered a URL that
            doesn't esxsist, but if you weren't expecting a 404 for this url
            please contact us at support@geeksfarm.com
          </p>
          {/* <button
            type="button"
            className="btn btn-primary-custom"
            onClick={handleBackHome}
          >
            {" "}
            GO TO HOMEPAGE
          </button> */}
        </div>
      </div>
    </>
  );
};

export default NotFoundOrigin;
