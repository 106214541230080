import axios from 'axios';

const token = localStorage.getItem('token');
const normalRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_CMS_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    app_code: 'wl-malaysia',
  },
});
delete normalRequest.defaults.headers.common['Authorization'];
export default normalRequest;

export const authCMSRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_CMS_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    app_code: 'wl-malaysia',
    token: token,
  },
});
authCMSRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  },
);

export const authEDURequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_EDU_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Method': '*',
    app_code: 'wl-malaysia',
    token: token,
  },
  auth: {
    username: 'api',
    password: 'booking',
  },
});

authEDURequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  },
);

const logout = async () => {
  try {
    // remove token from local storage and redirect to login page
    localStorage.removeItem('token');
    window.open('/login', '_self');
  } catch (e) {
    console.log(e);
  }
};

// authEDURequest.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       logout()
//     }

//     return Promise.reject(error);
//   }
// )
// authCMSRequest.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       logout()
//     }

//     return Promise.reject(error);
//   }
// )
