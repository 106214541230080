export const COURSE = {
  //GET ALL COURSE
  GET_ALL_COURSE: 'LEARNING_GET_ALL_COURSE',
  GET_ALL_COURSE_SUCCESS: 'LEARNING_ALL_GET_COURSE_SUCCESS',
  GET_ALL_COURSE_FAILED: 'LEARNING_ALL_GET_COURSE_FAILED',
  SET_COURSE_ID: 'LEARNING_SET_COURSE_ID',

  //GET TRENDING COURSE
  GET_TRENDING_COURSE: 'LEARNING_GET_TRENDING_COURSE',
  GET_TRENDING_COURSE_SUCCESS: 'LEARNING_TRENDING_GET_COURSE_SUCCESS',
  GET_TRENDING_COURSE_FAILED: 'LEARNING_TRENDING_GET_COURSE_FAILED',

  //GET COURSE BY ID
  GET_ALL_COURSE_BY_ID: 'LEARNING_GET_ALL_COURSE_BY_ID',
  GET_ALL_COURSE_BY_ID_SUCCESS: 'LEARNING_GET_ALL_COURSE_BY_ID_SUCCESS',
  GET_ALL_COURSE_BY_ID_FAILED: 'LEARNING_GET_ALL_COURSE_BY_ID_FAILED',

  //GET COURSE BY USER
  GET_COURSE_BY_USER: 'LEARNING_GET_COURSE_BY_USER',
  GET_COURSE_BY_USER_SUCCESS: 'LEARNING_GET_COURSE_BY_USER_SUCCESS',
  GET_COURSE_BY_USER_FAILED: 'LEARNING_GET_COURSE_BY_USER_FAILED',

  //GET COMMENT BY USER
  GET_ALL_COMMENT_BY_COURSE: 'LEARNING_GET_ALL_COMMENT_BY_COURSE',
  GET_ALL_COMMENT_BY_COURSE_SUCCESS:
    'LEARNING_GET_ALL_COMMENT_BY_COURSE_SUCCESS',
  GET_ALL_COMMENT_BY_COURSE_FAILED: 'LEARNING_GET_ALL_COMMENT_BY_COURSE_FAILED',

  // POST COMMENT
  POST_COMMENT: 'LEARNING_POST_COMMENT',
  POST_COMMENT_SUCCESS: 'LEARNING_POST_COMMENT_SUCCESS',
  POST_COMMENT_FAILED: 'LEARNING_POST_COMMENT_FAILED',

  //SEARCH COURSE
  SEARCH_COURSE: 'LEARNING_SEARCH_COURSE',
  SEARCH_COURSE_SUCCESS: 'LEARNING_SEARCH_COURSE_SUCCESS',
  SEARCH_COURSE_FAILED: 'LEARNING_SEARCH_COURSE_FAILED',

  //GET CATEGORY BY ID
  GET_CATEGORY_BY_ID: 'LEARNING_GET_CATEGORY_BY_ID',
  GET_CATEGORY_BY_ID_SUCCESS: 'LEARNING_GET_CATEGORY_BY_ID_SUCCESS',
  GET_CATEGORY_BY_ID_FAILED: 'LEARNING_GET_CATEGORY_BY_ID_FAILED',

  //GET BUY COURSE
  GET_BUY_COURSE: 'LEARNING_GET_BUY_COURSE',
  GET_BUY_COURSE_SUCCESS: 'LEARNING_GET_BUY_COURSE_SUCCESS',
  GET_BUY_COURSE_FAILED: 'LEARNING_GET_BUY_COURSE_FAILED',
  COURSE_HAS_BEEN_PAID: 'LEARNING_COURSE_HAS_BEEN_PAID',

  //GET BUY HISTORY COURSE
  GET_BUY_HISTORY: 'LEARNING_GET_BUY_HISTORY',
  GET_BUY_HISTORY_SUCCESS: 'LEARNING_GET_BUY_HISTORY_SUCCESS',
  GET_BUY_HISTORY_FAILED: 'LEARNING_GET_BUY_HISTORY_FAILED',

  //GET DETAIL COURSE
  GET_DETAIL_SCORE: 'LEARNING_GET_DETAIL_SCORE',
  GET_DETAIL_SCORE_SUCCESS: 'LEARNING_GET_DETAIL_SCORE_SUCCESS',
  GET_DETAIL_SCORE_FAILED: 'LEARNING_GET_DETAIL_SCORE_FAILED',

  //GET CONTENT COURSE
  GET_COURSE_CONTENT: 'LEARNING_GET_COURSE_CONTENT',
  GET_COURSE_CONTENT_SUCCESS: 'LEARNING_GET_COURSE_CONTENT_SUCCESS',
  GET_COURSE_CONTENT_FAILED: 'LEARNING_GET_COURSE_CONTENT_FAILED',

  // SEND RATING
  SEND_RATING: 'LEARNING_SEND_RATING',
  SEND_RATING_SUCCESS: 'LEARNING_SEND_RATING_SUCCESS',
  SEND_RATING_FAILED: 'LEARNING_SEND_RATING_FAILED',

  // GET RATING
  GET_RATING: 'LEARNING_GET_RATING',
  GET_RATING_SUCCESS: 'LEARNING_GET_RATING_SUCCESS',
  GET_RATING_FAILED: 'LEARNING_GET_RATING_FAILED',

  // POST LIKE
  POST_LIKE: 'LEARNING_POST_LIKE',
  POST_LIKE_SUCCESS: 'LEARNING_POST_LIKE_SUCCESS',
  POST_LIKE_FAILED: 'LEARNING_POST_LIKE_FAILED',

  // DELETE LIKE
  POST_DISLIKE: 'LEARNING_POST_DISLIKE',
  POST_DISLIKE_SUCCESS: 'LEARNING_POST_DISLIKE_SUCCESS',
  POST_DISLIKE_FAILED: 'LEARNING_POST_DISLIKE_FAILED',

  // reset course status
  RESET_COURSE_STATUS: 'LEARNING_RESET_COURSE_STATUS',

  CLEAR_BUY_COURSE: 'LEARNING_CLEAR_BUY_COURSE',

  // PAGINATION
  BUY_HISTORY_PAGINATION: 'BUY_HISTORY_PAGINATION',
  BUY_HISTORY_LIMIT: 'BUY_HISTORY_LIMIT',
  BUY_HISTORY_SEARCH: 'BUY_HISTORY_SEARCH',
  BUY_HISTORY_STATUS: 'BUY_HISTORY_STATUS',
};
