import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes, Route } from 'react-router-dom';

import Loading from '../component/Loading';
import ErrorPage from '../component/ErrorPage';
import WrapperComponent from '../component/WrapperComponent';

import { routes } from './Routes';

const routesItem = [...routes];

export default function Routing() {
  return (
    <Router>
      <WrapperComponent> 
      <ErrorBoundary fallback={<ErrorPage />}>
        <Suspense fallback={<Loading />}>
          <Routes>
            {routesItem.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.element}
                exact={true}
              />
            ))}
          </Routes>
        </Suspense>
      </ErrorBoundary>
      </WrapperComponent> 
    </Router>
  );
}
