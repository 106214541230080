import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useSelector, useDispatch } from 'react-redux';
import { authCMSRequest } from '../store/Api';
import { logout } from '../store/redux/actions/auth/login';

const PrivateRoute = ({ children }) => {
  // const [authToken, setAuthToken] = useState(null)

  // const dispatch = useDispatch()
  //     const fetchUserData = async () => {
  //       try {
  //         const response = await authCMSRequest.get('/api/v1/users/edit');
  //         // Handle the successful response
  //         setAuthToken(localStorage.getItem("token"))
  //         return response.data;
  //       } catch (error) {
  //         // Handle errors, including 401 Unauthorized
  //         if (error.response && error.response.status === 401) {
  //           // Trigger the logout process
  //           localStorage.removeItem('token');
  //           setAuthToken(null)
  //           dispatch(logout());

  //         }
  //         // Handle other errors (if needed)
  //         throw error;
  //       }
  //     };

  //     useEffect(() => {
  //         fetchUserData()
  //     }, [])
  //
  // const navigate = useNavigate()
  const history = createBrowserHistory();

  // useEffect(() => {
  //   localStorage.setItem('previousPath', window.location.pathname)
  //   window.addEventListener('popstate', handleBackButton);

  //   return () => {
  //     window.removeEventListener('popstate', handleBackButton);
  //   };
  // }, []);

  // const handleBackButton = () => {
  //   console.log('back from private route')

  //   history.push('/')
  //   history.back()
  // };

  // console.log('private route')

  const authToken = localStorage.getItem('token');

  if (!authToken) {
    // navigate('/login')
    history.push('/login');
  }

  return authToken && children;
};

export default PrivateRoute;
