export const QUIZ = {
  GET_QUIZ: 'LEARNING_GET_QUIZ',
  GET_QUIZ_SUCCESS: 'LEARNING_GET_QUIZ_SUCCESS',
  GET_QUIZ_FAILED: 'LEARNING_GET_QUIZ_FAILED',
  SET_QUIZ_ID: 'LEARNING_SET_QUIZ_ID',

  END_QUIZ: 'LEARNING_END_QUIZ',
  END_QUIZ_SUCCESS: 'LEARNING_END_QUIZ_SUCCESS',
  END_QUIZ_FAILED: 'LEARNING_END_QUIZ_FAILED',

  SET_QUIZ_TIME: 'LEARNING_SET_QUIZ_TIME',
  SET_QUIZ_TIME_REMAINING: 'LEARNING_SET_QUIZ_TIME_REMAINING',

  SET_USED_TIME: 'LEARNING_SET_USED_TIME',
  SET_TIME_SPENT: 'lEARNING_SET_TIME_SPENT',

  // get score
  GET_SCORE: 'LEARNING_GET_SCORE',
  GET_SCORE_SUCCESS: 'LEARNING_GET_SCORE_SUCCESS',
  GET_SCORE_FAILED: 'LEARNING_GET_SCORE_FAILED',
};
