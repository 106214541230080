export const USER = {
  GET_USER: 'USER_GET_USER',
  GET_USER_SUCCESS: 'USER_GET_USER_SUCCESS',
  GET_USER_FAILED: 'USER_GET_USER_FAILED',

  // Change password
  CHANGE_PASSWORD: 'USER_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: ' USER_CHANGE_PASSWORD_FAILED',

  // clear message
  CLEAR_MESSAGE: 'USER_CLEAR_MESSAGE',
};
