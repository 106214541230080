/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import logoLogin from '../assets/img/logo-geekfarms.svg';
import profile from '../assets/img/profile_no_image.jpg';
import '../assets/css/main.css';
import '../assets/css/custom-by-dev.css';
import '../assets/css/mobile.css';
import scrollToTop from '../utils/scrollToTop';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../store/redux/actions/profile/user';
import { getAllCourse } from '../store/redux/actions/learning/course';
import { getAllCategory } from '../store/redux/actions/learning/category';
import { searchCourse } from '../store/redux/actions/learning/course';
import { useGetCompany } from '../react-query/general';
import NavigationMenu from './moleculs/NavigationMenu';
import Loading from './Loading';

const Header = () => {
  const imgRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.user.user.data);
  const statusGetUser = useSelector((state) => state.user.status);
  const [user, setUser] = useState();
  const [auth, setAuth] = useState();
  const history = createBrowserHistory();
  const [search, setSearch] = useState('');
  const authToken = localStorage.getItem('token');
  const getCategory = useSelector((state) => state.category.category);
  const [categories, setCategories] = useState([]);
  const [isImageLoaded, setImageLoaded] = useState(null);

  const courseStatus = useSelector((state) => state.course.status);

  const { dataCompany, loadingDataCompany, errorDataCompany } = useGetCompany();

  const handleHome = () => {
    navigate('/');
    history.go(0);
  };

  const handleToLogin = () => {
    navigate('/login');
  };

  const handleToRegister = () => {
    navigate('/register');
  };

  const handleCourse = (course) => {
    navigate('/education', {
      state: { course_name: course.category_name, course_id: course.id },
    });
  };

  useEffect(() => {
    dispatch(getAllCourse());
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (authToken) {
      dispatch(getUser());
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      setAuth(authToken);
    } else if (!authToken) {
      setAuth(null);
    }
  }, [authToken]);

  useEffect(() => {
    const observer = handleLoadLogo();

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [imgRef.current, isImageLoaded]);

  const handleLoadLogo = () =>
    new IntersectionObserver(
      ([entry]) => {
        setImageLoaded(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

  const handleSubmitSearchCourse = (e) => {
    e.preventDefault();
    dispatch(searchCourse(search));
    setSearch('');
  };

  useEffect(() => {
    if (courseStatus === 'search success') {
      navigate('/course-list');
    }
  }, [courseStatus]);

  useEffect(() => {
    if (dataUser) {
      setUser(dataUser.user);
    }
  }, [dataUser]);

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    if (getCategory.status === 200) {
      setCategories(getCategory.data.data);
    }
  }, [getCategory]);

  useEffect(() => {
    setImageLoaded(dataCompany !== undefined);
  }, []);

  return (
    <>
      <nav className='navbar navbar-expand-lg'>
        <div className='container-fluid '>
          <a className='navbar-brand cursor-pointer'>
            {dataCompany && (
              <img
                ref={imgRef}
                src={
                  dataCompany[0]?.file_path_logo &&
                  dataCompany[0]?.file_path_logo
                }
                fetchpriority='high'
                onLoad={() => setImageLoaded(true)}
                onClick={handleHome}
                width={170}
                height={65}
                onError={(e) => {
                  e.target.src = logoLogin;
                }}
              />
            )}
            {/* {dataCompany ? (
              <img
                src={
                  dataCompany[0]?.file_path_logo &&
                  dataCompany[0]?.file_path_logo
                }
                onClick={handleHome}
              />
            ) : (
              <img src={logoLogin} onClick={handleHome} />
            )} */}
            {/* <img
              src={dataCompany ? dataCompany[0]?.file_path_logo : logoLogin}
              onClick={handleHome}
            /> */}
          </a>

          {/* <!-- Search for Mobile --> */}
          {/* <form
          className="col-12 col-lg-auto mb-3 mb-lg-0 mx-lg-3 for-mobile"
          onSubmit={handleSubmitSearchCourse}
        >
          <span className="form-control-feedback">
            <i className="fas fa-search" style={{ color: "#802ec0" }}></i>
          </span>
          <input
            type="search"
            className="form-control search-style"
            placeholder="What do you want to learn?"
            aria-label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form> */}

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div
            className='collapse has-search navbar-collapse'
            id='navbarSupportedContent'
          >
            {/* <ul className="navbar-nav navbar-custom">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Courses{" "}
                <span className="">
                  <i
                    className="fa-solid fa-angle-left fw-bold fs-6 ms-1"
                    style={{ rotate: "-90deg" }}
                  ></i>{" "}
                </span>
              </a>
              <ul className="dropdown-menu">
                {categories.map((category, index) => (
                  <li key={index}>
                    <a
                      className="dropdown-item"
                      onClick={() => handleCourse(category)}
                    >
                      <img src={category.file_path} /> {category.category_name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul> */}

            {/* <!-- Search for dekstop --> */}
            {/* <form
            className="col-12 col-lg-auto mb-3 mb-lg-0 mx-lg-3 for-desktop"
            onSubmit={handleSubmitSearchCourse}
          >
            <span className="form-control-feedback">
              <i className="fas fa-search" style={{ color: "#802ec0" }}></i>
            </span>
            <input
              type="search"
              className="form-control search-style"
              placeholder="What do you want to learn?"
              aria-label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form> */}

            {/* <ul className="navbar-nav navbar-custom">
            <li className="nav-item">
              <a className="nav-link" onClick={handleBootcamp}>
                Bootcamp
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={handleBlog}>
                Article
              </a>
            </li>
          </ul> */}
            {auth === null ? (
              <div className='d-flex ms-auto py-3 flex-row-reverse'>
                <button
                  type='button'
                  className='btn btn-primary-custom'
                  onClick={handleToRegister}
                >
                  Register
                </button>
                <button
                  type='button'
                  className='btn btn-outline-primary-custom'
                  onClick={handleToLogin}
                >
                  Login
                </button>
              </div>
            ) : (
              <div className='d-flex text-end'>
                <div className='dropdown after-log'>
                  <button
                    className='btn dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <div className='d-flex align-items-center'>
                      <div className='ava'>
                        <img
                          src={
                            user?.photo.thumb.url
                              ? user.photo.thumb.url
                              : profile
                          }
                        />
                      </div>
                      <div className='info'>
                        <h2>{user ? user.full_name : null}</h2>
                        <p className='mb-0'>My Account</p>
                      </div>
                    </div>
                  </button>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuButton1'
                  >
                    <NavigationMenu />
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
