import Router from './routes';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import './assets/css/global.css';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
