export const REGIS = {
  REGIS_STEP_1: 'REGIS_STEP_1',
  REGIS_STEP_2: 'REGIS_STEP_2',
  REGIS_STEP_3: 'REGIS_STEP_3',
  REGIS_STEP_4: 'REGIS_STEP_4',
  REGISTER: 'AUTH_REGIS',
  REGISTER_SUCCESS: 'AUTH_REGIS_SUCCESS',
  REGISTER_FAILED: 'AUTH_REGIS_FAILED',
  REGISTER_NEXT_STEP: 'AUTH_REGISTER_NEXT_STEP',
  REGISTER_PREV_STEP: 'AUTH_REGSITER_PREV_STEP',
  // google
  REGISTER_BY_GOOGLE: 'AUTH_REGISTER_BY_GOOGLE',
  REGISTER_BY_GOOGLE_SUCCESS: 'AUTH_REGISTER_BY_GOOGLE_SUCCESS',
  REGISTER_BY_GOOGLE_FAILED: 'AUTH_REGISTER_BY_GOOGLE_FAILED',
};
