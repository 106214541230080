import { authCMSRequest, authEDURequest } from '../Api';

export const getMentorById = (id) => {
  authEDURequest.get('/api/mentor/get-mentor/' + id);
};

export const getAllMentor = () => {
  authEDURequest.get('/api/mentor/get-all-mentor');
};

class mentorService {
  async getMentorById(id) {
    const response = await authEDURequest
      .get('/api/get-mentor/' + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }
  async getAllMentor() {
    const response = await authEDURequest
      .get('/api/mentor/get-all-mentor')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }
}

export default new mentorService();
