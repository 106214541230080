export const CATEGORY = {
  //GET ALL CATEGORY
  GET_ALL_CATEGORY: 'LEARNING_GET_ALL_CATEGORY',
  GET_ALL_CATEGORY_SUCCESS: 'LEARNING_GET_ALL_CATEGORY_SUCCESS',
  GET_ALL_CATEGORY_FAILED: 'LEARNING_GET_ALL_CATEGORY_FAILED',
  SET_CATEGORY_ID: 'LEARNING_SET_CATEGORY_ID',

  //GET CATEGORY BY ID
  GET_CATEGORY_BY_ID: 'LEARNING_GET_CATEGORY_BY_ID',
  GET_CATEGORY_BY_ID_SUCCESS: 'LEARNING_GET_CATEGORY_BY_ID_SUCCESS',
  GET_CATEGORY_BY_ID_FAILED: 'LEARNING_GET_CATEGORY_BY_ID_FAILED',
};
